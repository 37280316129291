// extracted by mini-css-extract-plugin
export var customOrder = "CustomOrder-module--customOrder--18N2x";
export var content = "CustomOrder-module--content--2Rl8Z";
export var titleContainer = "CustomOrder-module--titleContainer--1iX75";
export var title = "CustomOrder-module--title--GEaI2";
export var blackSubtitle = "CustomOrder-module--blackSubtitle--1WO7c";
export var blackSubtitle2 = "CustomOrder-module--blackSubtitle2--1FjHZ";
export var subtitle = "CustomOrder-module--subtitle--2yppa";
export var galleryContainer = "CustomOrder-module--galleryContainer--2Oyt1";
export var galleryTitle = "CustomOrder-module--galleryTitle--1XiwW";
export var gallery = "CustomOrder-module--gallery--22KdP";
export var galleryItem = "CustomOrder-module--galleryItem--1W-eX";
export var galleryImg = "CustomOrder-module--galleryImg--18lJE";
export var text = "CustomOrder-module--text--3JIqj";
export var text2 = "CustomOrder-module--text2--1FskN";
export var concierge = "CustomOrder-module--concierge--5viZK";
export var conciergeImg = "CustomOrder-module--conciergeImg--2JuAq";
export var textBox = "CustomOrder-module--textBox--sDLTF";
export var conciergeText = "CustomOrder-module--conciergeText--PmGVc";
export var conciergeSignature = "CustomOrder-module--conciergeSignature--1i1BD";
export var contacts = "CustomOrder-module--contacts--WTPbH";
export var conciergeService = "CustomOrder-module--conciergeService--1oKyt";
export var headphones = "CustomOrder-module--headphones--3HGg-";
export var hours = "CustomOrder-module--hours--3SmUG";
export var email = "CustomOrder-module--email--2509u";
export var link = "CustomOrder-module--link--23fMF";
export var tel = "CustomOrder-module--tel--26OBU";
export var num = "CustomOrder-module--num--sEZTC";
export var linkEmail = "CustomOrder-module--linkEmail--3v3BT";
export var btnContainer = "CustomOrder-module--btnContainer--1njHc";
export var button = "CustomOrder-module--button--15ccQ";
export var footerContainer = "CustomOrder-module--footerContainer--3mewt";