import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "utils/navigate";
import { useSiteMetadata } from "hooks/use-site-metadata";
import {
  Button,
  Header,
  Footer,
  SEO,
  QuotesTable,
  EvenMoreTable,
} from "components";
import {
  actionCreators,
  selectMenu,
  selectProducts,
} from "state/HomePage/reducer";
import anythingImg from "assets/anything.jpg";
import birthdayImg from "assets/birthday.jpg";
import conciergeImg from "assets/conciergeImg.svg";
import eventsImg from "assets/events.jpg";
import headphonesImg from "assets/headphones.svg";
import specialImg from "assets/special.jpg";
import sportsImg from "assets/sports.jpg";
import yourselfImg from "assets/yourself.jpg";

import * as styles from "./CustomOrder.module.scss";

if (typeof window !== "undefind" && typeof document !== "undefined") {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

const CustomOrderPage = ({ menu }) => {
  const { landing, shared } = useSiteMetadata();
  const [isMount, setMount] = useState(false);

  const campaignName = landing.CAMPAIGN.name;
  const quickSearchKeys = shared.QUICK_SEARCH_KEYS;

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMount) {
    return null;
  }

  return (
    <div className={styles.customOrder}>
      <SEO
        title="Fan Made Fits - Custom Orders"
        description="Create custom orders with fanmadefits.com. VIPs get full access to professional designers for free. Start your custom project today!"
        pathname="/customOrder"
      />
      <Header isNeedDescription={!!menu?.selectedCollection} />

      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>
            Create Your Own Custom Order. Best Price And Service, Trust Us We
            Checked!
          </p>
        </div>
        <div className={styles.blackSubtitle}>
          <p className={styles.subtitle}>
            VIP Members get unlimited orders, access to professional designers,
            and their own personal concierge for FREE.
          </p>
        </div>
        <div className={styles.galleryContainer}>
          <p className={styles.galleryTitle}>Start a custom order for:</p>
          <div className={styles.gallery}>
            <div className={styles.galleryItem}>
              <img
                className={styles.galleryImg}
                src={yourselfImg}
                alt="Yourself"
              />
              <p className={styles.text}>Yourself</p>
            </div>
            <div className={styles.galleryItem}>
              <img
                className={styles.galleryImg}
                src={birthdayImg}
                alt="Birthdays"
              />
              <p className={styles.text}>Birthdays</p>
            </div>

            <div className={styles.galleryItem}>
              <img
                className={styles.galleryImg}
                src={specialImg}
                alt="Special Someone"
              />
              <p className={styles.text2}>Special Someone</p>
            </div>

            <div className={styles.galleryItem}>
              <img className={styles.galleryImg} src={eventsImg} alt="Events" />
              <p className={styles.text}>Events</p>
            </div>

            <div className={styles.galleryItem}>
              <img
                className={styles.galleryImg}
                src={sportsImg}
                alt="Sports Team"
              />
              <p className={styles.text2}>Sports Team</p>
            </div>

            <div className={styles.galleryItem}>
              <img
                className={styles.galleryImg}
                src={anythingImg}
                alt="Anything!"
              />
              <p className={styles.text}>Anything!</p>
            </div>
          </div>
        </div>
      </div>

      <QuotesTable />

      <div className={styles.blackSubtitle2}>
        <p className={styles.subtitle}>
          Thinking about joining VIP Membership? Here is what you can expect!
        </p>
      </div>

      <EvenMoreTable />

      <div className={styles.concierge}>
        <div className={styles.conciergeImgBox}>
          <img
            src={conciergeImg}
            alt="Concierge sercice"
            className={styles.conciergeImg}
          />
        </div>
        <div className={styles.textBox}>
          <p className={styles.conciergeText}>
            Hey, Susan here! I'm going to be your personal concierge. What does
            that mean? Well, I can help you with any questions, special
            requests, or custom orders! It's my goal to build real genuine
            relationships with FMF members. Don't be shy, feel free to drop me a
            message about anything!
          </p>
          <p className={styles.conciergeSignature}>-Susan, Head Concierge</p>
        </div>
      </div>

      <div className={styles.contacts}>
        <div className={styles.conciergeService}>
          <img
            className={styles.headphones}
            src={headphonesImg}
            alt="Contact Us"
          />
          <p className={styles.hours}>Concierge Service Hours</p>
          <p className={styles.num}>24/7</p>
          <p className={styles.email}>Email:</p>
          <a
            className={styles.link}
            href={`mailto:concierge@fanmadefits.com?subject=Concierge Order`}
          >
            concierge@fanmadefits.com
          </a>
          <p className={styles.tel}>Text:</p>
          <p className={styles.num}>855-701-0832</p>
        </div>
        <div className={styles.btnContainer}>
          <div className={styles.button}>
            <a
              className={styles.linkEmail}
              href={`mailto:concierge@fanmadefits.com?subject=Concierge Order`}
            >
              <Button value="Start a Custom Order >" className="buttonHuge" />
            </a>
          </div>
          <div className={styles.button}>
            <Button
              value="Ask Susan Questions >"
              className="buttonDarkBlueHuge"
              path="/support"
            />
          </div>
          <div className={styles.button}>
            <Button
              value="Check out our FAQs >"
              className="buttonDarkBlueHuge"
              path="/faq"
            />
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  products: selectProducts(state),
});

const mapDispatchToProps = {
  setSearchValue: actionCreators.setSearchValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrderPage);
